import { useAddCompletedSlackInviteMutation } from "@/apps/list/app/slack/util/__generated__/useAddCompletedSlackInviteMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import Relay from "@/relay/relayUtils"
import { useCallback } from "react"
import { graphql } from "react-relay"

function useAddCompletedSlackInvite() {
  const { authUser } = useAuthUser({ required: true })
  const activeOrganization = useActiveOrganization()!

  const addCompletedSlackInviteMutation =
    Relay.useAsyncMutation<useAddCompletedSlackInviteMutation>(
      graphql`
        mutation useAddCompletedSlackInviteMutation(
          $input: CreateOrganizationSlackMembershipInput!
        ) {
          createOrganizationSlackMembership(input: $input) {
            node {
              id
              userId
              organizationId
              organizationMembershipId
            }
            errors {
              field
              message
            }
          }
        }
      `
    )

  return useCallback(async () => {
    await addCompletedSlackInviteMutation({
      input: {
        userId: authUser.id,
        organizationId: activeOrganization.id,
      },
    })
  }, [addCompletedSlackInviteMutation, authUser.id, activeOrganization.id])
}

export default useAddCompletedSlackInvite
