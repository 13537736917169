import { ToggleAppStatusButtonFragment$key } from "@/apps/actions/__generated__/ToggleAppStatusButtonFragment.graphql"
import { ToggleAppStatusButtonMutation } from "@/apps/actions/__generated__/ToggleAppStatusButtonMutation.graphql"
import { useAppLabel } from "@/apps/util/appUtil"
import { useLabel } from "@/core/context/LabelsContext"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"

type Props = {
  appKey: ToggleAppStatusButtonFragment$key
  children: OverridableDiscoButtonChildren
}

export default function ToggleAppStatusButton({ appKey, children }: Props) {
  const app = useFragment<ToggleAppStatusButtonFragment$key>(
    graphql`
      fragment ToggleAppStatusButtonFragment on ProductApp {
        id
        status
        kind
        product {
          type
        }
      }
    `,
    appKey
  )

  const [commit, isSubmitting] = useMutation<ToggleAppStatusButtonMutation>(
    graphql`
      mutation ToggleAppStatusButtonMutation($input: UpdateProductAppInput!) {
        response: updateProductApp(input: $input) {
          node {
            id
            status
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const [isOpen, setIsOpen] = useState(false)
  const appLabel = useAppLabel(app)
  const membersLabel = useLabel("admin_member")
  const productLabel = useAdminProductLabel(app.product?.type)
  const isActive = app.status === "active"
  const action = isActive ? "Hide" : "Unhide"
  const title = `${action} ${appLabel} App for ${membersLabel.plural}`

  return (
    <>
      <OverridableDiscoButton onClick={() => setIsOpen(true)}>
        {children}
      </OverridableDiscoButton>

      <DiscoWarningModal
        testid={"ToggleAppStatusButton.modal"}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        variant={"primary"}
        modalContentLabel={title}
        title={title}
        description={
          isActive
            ? `The ${appLabel} app will be hidden from ${membersLabel.plural}. You can always unhide it later from the dropdown menu in the left navigation.`
            : `The ${appLabel} app will be unhidden and become visible to all ${
                membersLabel.plural
              }. This allows everyone in the ${
                app.product ? productLabel.singular : "community"
              } to access and engage with this app.`
        }
        confirmationButtonProps={{
          children: action,
          onClick: handleSubmit,
          shouldDisplaySpinner: isSubmitting,
        }}
        hideIcon
      />
    </>
  )

  function handleSubmit() {
    commit({
      variables: {
        input: {
          id: app.id,
          status: isActive ? "inactive" : "active",
        },
      },
      onCompleted({ response }) {
        if (response.errors?.length) return displayErrorToast(response.errors[0].message)

        displayToast({
          message: `App ${isActive ? "hidden" : "unhidden"}!`,
          testid: "ToggleAppStatusButton.success-toast",
        })
        setIsOpen(false)
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }
}
