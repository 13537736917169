import ChatChannelSettingsModal from "@/apps/list/app/chat/ChatChannelSettingsModal"
import Relay from "@/relay/relayUtils"
import { DiscoButtonSkeleton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

interface Props extends OverridableDiscoButtonProps {
  testid?: string
  /** Create chat channel at the product level, default to community level */
  productLevel?: boolean
}

function CreateChatChannelButton({
  children = "Add Channel",
  testid = "CreateChatChannelButton",
  productLevel,
}: Omit<Props, "children"> & { children?: OverridableDiscoButtonChildren }) {
  const isMobile = useIsMobile()
  return (
    <OverridableDiscoButton
      testid={`${testid}.create-channel-button`}
      leftIcon={"add"}
      modal={({ isOpen, onClose }) => {
        // Reset form state on each open
        if (!isOpen) return null
        return (
          <ChatChannelSettingsModal
            testid={"CreateChatChannelButton"}
            isOpen={isOpen}
            productLevel={productLevel}
            onClose={onClose}
          />
        )
      }}
    >
      {typeof children === "string" ? (isMobile ? "Add" : children) : children}
    </OverridableDiscoButton>
  )
}

export default Relay.withSkeleton<
  Omit<Props, "children"> & { children?: OverridableDiscoButtonChildren }
>({
  component: CreateChatChannelButton,
  skeleton: DiscoButtonSkeleton,
})
